import React from 'react';
import { useState } from 'react';
import css from './Header.module.css';
import Logo from '../../assets/logo.png';
import {HiOutlineShoppingBag } from 'react-icons/hi';
import { FaBars } from 'react-icons/fa';


function Header() {
  const [showMenu, setShowMenu] = useState(true);
  const toggleMenu=()=> {
    setShowMenu(showMenu=>!showMenu)
  }
  return (
    <div className={css.container}>
      <div className={css.logo}>
        <img src={Logo} alt="Logo" />
        <span>Amazon</span>
      </div>
      <div className={css.right}>
        <div className={css.bars} onClick={toggleMenu}>
          <FaBars/>
        </div>
        
          <ul className={css.menu} style={{display:showMenu?'inherit':'none'}}>
            <li>Collections</li>
            <li>Brands</li>
            <li>New</li>
            <li>Sales</li>
            <li>ENG</li>
          </ul>
        

        <input placeholder='Search' className={css.search} type='text'></input>

        <HiOutlineShoppingBag className={css.cart} />
      </div>
    </div>
  )
}

export default Header