import React from 'react';
import css from './Footer.module.css';
import { InboxIcon,PhoneIcon,LocationMarkerIcon,LoginIcon,UserIcon,LinkIcon, LogoutIcon } from '@heroicons/react/outline';
import Logo from '../../assets/logo.png';

function Footer() {
  return (
      <div className={css.cFooterWrapper}>
      <hr />
      
      <div className={css.cFooter}>
        {/* logo */}
        <div className={css.logo}>
          <img src={Logo} alt="" />
          <span>Amazon</span>
        </div>

        {/* contact */}
        <div className={css.block}>
        <div className={css.detail}>
            <span>Contact us</span>
            {/* lists */}
          <span className={css.pngLine}>
            <LocationMarkerIcon className={css.icon} />
              <span>111 north avenue orlando</span>
            </span>
            <span className={css.pngLine}>
            <PhoneIcon className={css.icon} />
              <span>111 north avenue orlando</span>
              
            </span>
            <span className={css.pngLine}>
            <InboxIcon className={css.icon} />
              <span>111 north avenue orlando</span>
              
            </span>
            
        </div>
        </div>

        {/* account */}
        <div className={css.block}>
        <div className={css.detail}>
            <span>Account</span>
            {/* lists */}
          <span className={css.pngLine}>
            <LoginIcon className={css.icon} />
              <span>111 north avenue orlando</span>
            </span>
            <span className={css.pngLine}>
            <LogoutIcon className={css.icon} />
              <span>111 north avenue orlando</span>
              
            </span>
           
            
        </div>
        </div>
        {/* company */}
        <div className={css.block}>
        <div className={css.detail}>
            <span>Company</span>
            {/* lists */}
          <span className={css.pngLine}>
            <UserIcon className={css.icon} />
              <span>111 north avenue orlando</span>
            </span> 
        </div>
        </div>

         {/* resources */}
         <div className={css.block}>
        <div className={css.detail}>
            <span>Resources</span>
            {/* lists */}
          <span className={css.pngLine}>
            <LinkIcon className={css.icon} />
              <span>111 north avenue orlando</span>
            </span> 
        </div>
        </div>
      </div>

      <div className={css.copyRight}>
        <span>Copyright @2023
          by Amazon, Inc.
        </span>
        <span>All rights reserved.</span>
      </div>
    </div>
  )
}

export default Footer