import React from 'react';
import css from './Hero.module.css';
import HeroImg from '../../assets/hero.png';
import { AiFillShopping } from 'react-icons/ai';
import { BiRightArrowAlt } from 'react-icons/bi';
// adding animate
import { motion } from 'framer-motion';


function Hero() {
    const transition={duration:3,type:'spring'}
  return (
      <div className={css.container}>
          {/* left side */}
          <div className={css.h_side}>
              <span className={css.text1}>skin protection cream</span>

              <div className={css.text2}>
                  <span>Trendy Collection</span>
                  <span>The Aeneid is a Latin epic poem, written by Virgil between 29 and 19 BC</span>
              </div>
          </div>

          {/* middle img */}
          <div className={css.wrapper}>
            {/* blue circle */}
              <motion.div initial={{ bottom: "2rem" }}
                  whileInView={{bottom:"0rem"}}
                  className={css.blueCircle} transition={transition}></motion.div>
              
              <motion.img initial={{bottom:'-2rem'}} whileInView={{bottom:'0rem'}} transition={transition} src={HeroImg} alt="" width={600} />

              <motion.div initial={{right:'4%'}} whileInView={{right:'2%'}} transition={transition} className={css.cart2}>
                  <AiFillShopping />
                  
                  <div className={css.signup}>
                      <span>best signup offers</span>

                      <div>
                          <BiRightArrowAlt/>
                      </div>
                  </div>
              </motion.div>
          </div>

          {/* right side */}
          <div className={css.h_side}>
              <div className={css.traffic}>
                  <span>1.5m</span>
                  <span>Month Traffic</span>
              </div>
              <div className={css.customers}>
                  <span>100K</span>
                  <span>Happy Customers</span>
              </div>
          </div>


        </div>
  )
}

export default Hero